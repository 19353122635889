import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'

import { useEffect } from 'react'

import {
  TypeVisitorState,
  dataLayerEventHome,
  dataLayerEventProductClick,
  dataLayerEventPromotionClick,
  dataLayerModelHome,
  useUser,
  useVisitor
} from '@k_frontend/core'
import { Col, Container, Row, SrOnly } from '@k_frontend/ui'
import { RuntimeLoader } from '@rive-app/react-canvas'
import UpperBanners from 'components/PageHome/Banners/UpperBanners'
import MainBanner from 'components/PageHome/MainBanner'
import OffersHeader from 'components/PageHome/OffersHeader'
import { CatalogCarousel } from 'components/ProductsCarousel'
import { formatBanners } from 'models/bannerToDataLayer/bannerToDataLayer'
import { BannerModel } from 'models/home/home.types'
import { offersHomeProductsToCatalog } from 'models/offers'

import { getBanners } from 'services/home'
import { KABUM_URL, RIVE_WASM_URL } from 'settings/kernel'
import * as SEO from 'settings/seo'
import { Product } from 'types/catalog'
import { formatProduct } from 'utils/formatProductToDataLayer'

import { OffersService } from 'services/offers'
import { shallow } from 'zustand/shallow'
import * as S from './home.styles'
import { TypeHomeProps } from './home.types'

const DynamicWrapperHome = dynamic(
  () => import('components/PageHome/WrapperHome/WrapperHome'),
  {
    ssr: false
  }
)

const UpdateDataPJCustomerModal = dynamic(
  () => import('components/UpdateDataPJCustomerModal'),
  {
    ssr: false
  }
)

RuntimeLoader.setWasmUrl(RIVE_WASM_URL)

const useVisitorSelector = (state: TypeVisitorState) => ({
  visitorFetchedData: state.fetchedData,
  visitorWasPushed: state.visitorWasPushed
})

function Home({ data }: TypeHomeProps) {
  const { campaignOfferData, banners, isLogged, cookieIsMobile } = data
  const shouldPreloadRive = banners?.mainBanner?.find(
    (banner) => banner?.isAnimated
  )

  const activeCampaign = Boolean(campaignOfferData?.products?.length > 0)
  const { visitorFetchedData, visitorWasPushed } = useVisitor(
    useVisitorSelector,
    shallow
  )
  const isUserPrime = useUser((state) => state.isPrime)

  function dataLayerHome() {
    const dataLayer = dataLayerModelHome(campaignOfferData, isUserPrime)
    dataLayerEventHome({
      ...dataLayer,
      pageType: 'home',
      offerPath: `oferta-${campaignOfferData?.info?.path}`
    })
  }

  function dataLayerProductClick(product: Product, smarthint?: boolean): void {
    dataLayerEventProductClick({
      products: formatProduct({ products: [product], smarthint }),
      list: product?.list,
      smarthint
    })
  }

  function dataLayerBannerClick(banner: BannerModel) {
    dataLayerEventPromotionClick(formatBanners([banner]))
  }

  useEffect(() => {
    const shouldPushVisitor =
      (!isLogged || visitorFetchedData) && visitorWasPushed
    if (shouldPushVisitor) {
      dataLayerHome()
    }
  }, [visitorFetchedData, visitorWasPushed])

  return (
    <>
      <Head>
        <title>
          KaBuM! | Aperte o K e evolua com as maiores ofertas em Tech e Gamer!
        </title>
        <meta
          name='title'
          content='KaBuM! | Aperte o K e evolua com as maiores ofertas em Tech e Gamer!'
        />
        <meta
          name='description'
          content='As melhores ofertas em tecnologia, hardware e games estão prontas para você conferir! Aperte o K e evolua com o KaBuM!.'
        />
        <link rel='canonical' href={KABUM_URL}></link>
        <meta property='og:url' content='https://www.kabum.com.br' />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content='KaBuM! | Maior E-commerce de Tecnologia e
Games da América Latina'
        />
        <meta
          property='og:description'
          content='Encontre as melhores ofertas em
Tecnologia e Games aqui no KaBuM!. Confira nossa linha de Hardware,
Smartphones, Computadores, Eletrônicos e mais. Acesse!'
        />
        <meta
          property='og:image'
          content='https://cdn.cookielaw.org/logos/786147c0-ae7b-45e8-af2b-6957a5af9fe0/e01396ef-c6fc-4c43-8a50-d66ab1a8448c/9f986df4-a13b-4f3e-add9-6f044ea6e47e/140.png'
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='www.kabum.com.br' />
        <meta name='twitter:site' content='@kabumcombr' />
        <meta property='twitter:url' content='https://www.kabum.com.br' />
        <meta
          name='twitter:title'
          content='KaBuM! | Maior E-commerce de Tecnologia
          e Games da América Latina'
        />
        <meta
          name='twitter:description'
          content='Encontre as melhores ofertas em
          Tecnologia e Games aqui no KaBuM!. Confira nossa linha de Hardware,
          Smartphones, Computadores, Eletrônicos e mais. Acesse!'
        />
        <meta
          name='twitter:image'
          content='https://cdn.cookielaw.org/logos/786147c0-ae7b-45e8-af2b-6957a5af9fe0/e01396ef-c6fc-4c43-8a50-d66ab1a8448c/9f986df4-a13b-4f3e-add9-6f044ea6e47e/140.png'
        />
        {shouldPreloadRive && (
          <link
            rel='preload'
            href={RIVE_WASM_URL}
            as='fetch'
            crossOrigin='anonymous'
          />
        )}
      </Head>
      <Script id='SmartHint_PageType' type='application/javascript'>
        const SmartHint_PageType = `home`
      </Script>
      <Script
        type='application/javascript'
        id='googleTagParams'
        dangerouslySetInnerHTML={{
          __html: `var google_tag_params = {
            prodid: '',
            pagetype: 'home',
            pname: '',
            pcat: '',
            value: ''
          };`
        }}
      />
      <Script
        type='application/javascript'
        id='googleTag'
        dangerouslySetInnerHTML={{
          __html: `/* <![CDATA[ */
            var google_conversion_id = 1014898465;
            var google_custom_params = window.google_tag_params;
            var google_remarketing_only = true;
            /* ]]> */`
        }}
      />
      <Script
        type='text/javascript'
        src='//www.googleadservices.com/pagead/conversion.js'
      ></Script>
      <noscript>
        <div style={{ display: 'inline' }}>
          <img
            height='1'
            width='1'
            style={{ borderStyle: 'none' }}
            alt='Selo de Segurança'
            src='//googleads.g.doubleclick.net/pagead/viewthroughconversion/1014898465/?value=0&amp;guid=ON&amp;script=0'
          />
        </div>
      </noscript>

      <S.Wrapper backgroundColor={banners?.mainBanner?.[0]?.background}>
        <SrOnly as='h1'>{SEO.SITE_NAME}</SrOnly>
        <S.GridGeral>
          <MainBanner
            mainBanner={banners?.mainBanner}
            duration={banners?.mainDuration}
            cookieIsMobile={cookieIsMobile}
            dataLayerBannerClick={dataLayerBannerClick}
          />
          {activeCampaign && (
            <S.OfferCampaignSession aria-labelledby='active-campaign-1'>
              <Container
                backgroundColor={campaignOfferData.info.mainColor}
                id='main-campaign'
              >
                <OffersHeader offerInfo={campaignOfferData.info} />
              </Container>
              <S.Container
                sm={{
                  overflowRight: true
                }}
                md={{
                  overflowRight: true
                }}
              >
                <Row className='doublePadding'>
                  <Col>
                    <CatalogCarousel
                      id='carousel-offers'
                      list={offersHomeProductsToCatalog(
                        campaignOfferData?.products,
                        campaignOfferData?.info
                      )}
                      onProductClick={dataLayerProductClick}
                      header={false}
                      showAll={{
                        link: `/ofertas/${campaignOfferData?.info?.path}`,
                        id: 'linkListagemOfertasCampanha'
                      }}
                      isOverflow
                      cookieIsMobile={cookieIsMobile}
                    />
                  </Col>
                </Row>
              </S.Container>
            </S.OfferCampaignSession>
          )}
          <S.Container>
            <UpperBanners />
          </S.Container>
          <DynamicWrapperHome
            banners={banners}
            dataLayerBannerClick={dataLayerBannerClick}
            dataLayerProductClick={dataLayerProductClick}
            cookieIsMobile={cookieIsMobile}
          />
        </S.GridGeral>
        <UpdateDataPJCustomerModal />
      </S.Wrapper>
    </>
  )
}

export async function getServerSideProps({ req, res }) {
  const classCookie = req.cookies
  const clientId = classCookie.id_cliente || ''
  const isPrime = classCookie?.CP_1286400 === 'true'

  const offersService = new OffersService()

  const bannersPromise = getBanners(isPrime)
  const campaignOfferDataPromise = offersService.getOffers()

  const promisesData = await Promise.allSettled([
    bannersPromise,
    campaignOfferDataPromise
  ])

  const [banners, campaignOfferData] = promisesData.map((promisesResponse) =>
    promisesResponse.status === 'fulfilled' ? promisesResponse.value : []
  )

  const data = {
    banners,
    campaignOfferData,
    isLogged: !!clientId,
    cookieIsMobile: classCookie?.isMobile === 'true',
    clientId
  }

  res.setHeader('Cache-Control', 'public, max-age=60')
  return { props: { data } }
}

export default Home
